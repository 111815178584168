
<mat-card appearance="outline" class="card-container">
	<div class="dialog-base">
		<div class="title top-s4">
			<button mat-button class="floating-close-button" matTooltip="Close" matDialogClose><mat-icon>close</mat-icon></button>
		</div>
		<app-account-plan
				[user]="user"
				[orgList]="orgList"
				[selectedOrg]="selectedOrg"
				(updateOrgList)="closeDialog()">
		</app-account-plan>
	</div>
</mat-card>
