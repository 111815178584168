/* Imports */
import {
	Component,
	OnInit,
	OnDestroy,
	ViewChild,
	Input,
	Output,
	EventEmitter,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import moment from 'moment';
import {
	AlertService,
	OrganizationService,
	FinanceService,
	UtilsService,
	TitleService,
	ChargebeeService,
	byId, PermissionService
} from "@shared/services";
import { Alert, Organization, Plan } from "@shared/models";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from '@angular/material/stepper';
import { ConfirmationModal } from "@app/components";
import {Subscription} from "@shared/services/subscription.service";
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";
import { analyticsLayer } from '@shared/analyticsLayer';
import { isNil } from "lodash";

interface Period {
	value: string;
	name: string;
	list: Array<any>;
}

const createRenewalMessage = (sub) =>
	`${
		sub.renew
			? "Renews"
			: "Expires"
	}
	on ${(Subscription.getEndOfSubscriptionDate(sub) as moment.Moment).format("MMM DD, YYYY")}`;

const pickPlanDisplayInfo = ({
								 name = "N/A",
								 id = null,
								 description = "",
								 period_unit = "year",
								 price = null,
							 } = {} ) => {
	let outDescription = null;
	if (description?.length) {
		const [header, ...details] = description.split("\n- ");
		outDescription = {
			header,
			details,
		};
	}
	return {
		name,
		id,
		price: parseInt(price) * .01, // price comes without the proper decimal point e.g. 680400 should be 6,804.00. This corrects the issue.
		isYearly: period_unit === "year",
		expirationMessage: "",
		description: outDescription,
	};
};

const planText = {
	"active": "Active",
	"paused": "Paused",
	"cancelled": "Cancelled" ,
	"non_renewing": "Not Renewing"
};

var numRetriesRemain = 10;

@Component({
	selector: "app-account-plan",
	templateUrl: "./plan.component.html",
	styleUrls: ["./plan.component.scss"],
})
export class PlanComponent implements OnInit, OnDestroy {

	get selectedTeam(): any {
		return this._selectedTeam;
	}

	set selectedTeam(value: any) {

		// TODO: @remove remove-subscription-required : subscription and chargebee checks not needed
		if(value?.subscription){
			value.subscription = Subscription.setSubscription(value.subscription);
			this.subscriptionPlanId = String(value.subscription.plan_id);
			this.chargeBeePlansForDisplay = this.displayPlans.find(p => p.id === value.subscription.plan_id) ?? pickPlanDisplayInfo();
			this.chargeBeePlansForDisplay.expirationMessage = createRenewalMessage(value.subscription);
			this.isSubscribed = true;
		}else{
			this.chargeBeePlansForDisplay = pickPlanDisplayInfo();
			this.subscriptionPlanId = null;
			this.isSubscribed = false;
		}
		this._selectedTeam = value;
	}

	@Input("user") user: any;
	@Input("orgList") orgList: Array<any>;
	@Input("selectedOrg") selectedOrg: any;
	@Input("addNewCard") addNewCard: boolean;

	@Output("updateOrgList") updateOrgList: EventEmitter<boolean> = new EventEmitter();
	@Output("fullPage") fullPage: EventEmitter<boolean> = new EventEmitter();
	@Output("freeToPaid") freeToPaid: EventEmitter<
		boolean
	> = new EventEmitter();
	@ViewChild(MatStepper) stepper: MatStepper;

	public cancelCheckboxes: Array<boolean> = [false, false, false, false];

	public cardForm: FormGroup;
	public displayPage: string = "default";
	public cardList: Array<any> = [];
	public selectedCard: any;
	private _selectedTeam: any = null;
	public cancelError: string = null;

	// TODO: @remove remove-subscription-required : variables which should no longer be needed
	public showUpdateError: boolean = false;
	public displayPlanYearly: boolean = false;
	public showPlanChangeNotification: boolean = false;
	public isSubscribed: boolean = false;
	public isProcessing: boolean = true;
	public showChargebeeError: boolean = false;

	public currentPlan: any;
	private _subscription: any = null;
	public billingHistoryOptions = [
		{ text: "One month", value: "one_month" },
		{ text: "Two months", value: "two_month" },
		{ text: "Three months", value: "three_month" },
		{ text: "Six months", value: "six_month" },
		{ text: "One year", value: "one_year" },
		{ text: "Complete history", value: "complete" },
	];
	public billingHistory: any = this.billingHistoryOptions[3];
	// @ts-ignore
	chargeBeePlansForDisplay = pickPlanDisplayInfo();
	displayPlans = [];
	public subscriptionPlanId: string;
	public newPlanForDisplay: any;
	// TODO: @remove-end subscription-required

	public planOptions: { month: Plan[], year: Plan[] } = null;

	public periodOptions: Period[] = [
		{value: "month", name: "Monthly", list: []},
		{value: "year", name: "Annually", list: []},
	];
	public selectedPeriod: Period = this.periodOptions[1];
	public selectedPlan: Plan = null;
	private _planList: Plan[] = [];
	public chargebeeInstance = null;
	public userIsAdmin: boolean = false;
	public teamRole = {
		admin: 0,
		process: 0,
		reader: 0
	}

	get hasPaymentCards(): boolean {
		return this.cardList && this.cardList.length > 0;
	}

	constructor(
		private _dialog: MatDialog,
		private _alertService: AlertService,
		private _orgService: OrganizationService,
		private _financeService: FinanceService,
		private _titleService: TitleService,
		private _cbService: ChargebeeService,
		private _permissionService: PermissionService
	) {
		this.displayPage = "default";
	}

	ngOnInit() {
		// TODO: @remove remove-subscription-required : refactor the page title
		this._titleService.setTitle("Plans & billing");

		if(flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) {
			if(flagLayer.isEnabled(availableFeatureFlags.newSubscription)) {
				this._titleService.setTitle( "Subscriptions" );

				// TODO: @remove remove-subscription-required : remove chargebee plans
				this._financeService
					.getChargeBeePlans()
					.then((plans) => {
						this.displayPlans = plans.map(pickPlanDisplayInfo);
					})
			} else {
				this._financeService
					.getProducts().then(plans => {
					this.displayPlans = plans.map(pickPlanDisplayInfo);
				})
			}
		}

		analyticsLayer.trackPage();

		// check if the org has already been pre-selected
		if(this.selectedOrg)
		{
			this.selectTeam(this.selectedOrg);

			// auto open the add new credit card sub-page
			if(this.addNewCard)
				this.displayPage = "payment";
		}

		this.makeChargebeeInstance();

	} // End-of ngOnInit

	ngOnDestroy() {
		this.clearChargebeeInstance();
	}

	togglePage(
		page: string = "default",
		fullscreen: boolean = false,
		card?: any
	): void {
		if (fullscreen) {
			this.fullPage.emit(true);
		}
		if (page === "payment" && card) {
			this.selectedCard = card;
		} else {
			this.selectedCard = null;
		}
		if (page === "default" && this.selectedTeam) {
			this.selectedTeam = null;
		}
		this.showUpdateError = false;
		this.displayPage = page;
	}

	async selectTeam(org: Organization) {
		await this.checkUserPermission(org);
		this.clearChargebeeInstance();
		this.selectedTeam = org;
		this.showPlanChangeNotification = false;
		this.getLicenseSeats();

		if (flagLayer.isEnabled( availableFeatureFlags.newSubscription )) {
			this.isProcessing = true;
			this.getChargebeeSubscriptions()
				.then(plans =>
					this.updateActivePlans(plans, org))
				.then(plans =>
					this.getSubscriptionPlanOptions(plans)
				).catch(err => {
					console.error(err)
				});
		} else {
			// TODO: Future Finance Service Work
		}

		if(this.displayPage != "payment")
			this.togglePage("view");
	}

	async updateActivePlans( plans: Plan[], org: Organization ): Promise<Plan[]> {
		if (this.hasOrgPlan( org )) {
			this.setSelectedPlanById(plans, org.subscription?.plan_id)
			this.setSelectedPeriodByOrg(org);
		}
		return plans;
	}

	hasOrgPlan = (org: Organization): boolean =>
		org.subscription && !this.isInTrial(org);

	isOrgPlan = (plan: Plan, org: Organization): boolean =>
		this.hasOrgPlan(org) && plan.id === org.subscription?.plan_id;

	hasNoPlanOrCurrentPlan = (plan: Plan, org: Organization): boolean =>
		!this.hasOrgPlan(org) || (this.isOrgPlan(plan, org));

	getSubscriptionPlanOptions(plans: Array<Plan>): void {
		this.planOptions = plans.reduce((acc, plan: Plan) => {
			const period = this.periodOptions.find(option =>
				option.value === plan.period_unit
			);

			if (plan.id === "PAYG") {
				acc.month.unshift(plan);
				acc.year.unshift(plan);
			} else if (period?.value) {
				period.list?.push(plan);
				acc[period.value].push(plan);
			}
			return acc;
		}, {month: [], year: []});

		this.checkPeriodOptions();
		this.isProcessing = false;
	}

	checkPeriodOptions() {
		this.periodOptions = this.periodOptions.filter(x => x.list.length);
		if (this.periodOptions.length === 1) {
			this.selectedPeriod = this.periodOptions[0];
		}
	}

	goBack(page: string = "default"): void {
		this.selectedTeam = null;
		this.planOptions = null;
		this.showUpdateError = false;
		this.togglePage(page);
	}

	checkAllChecked(): boolean {
		return this.cancelCheckboxes.every((x) => x === true);
	}

	sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}

	isUserAdmin(): boolean {
		const org = this.orgList.find(o => o.id === this.selectedTeam.id);
		const user = org?.users.find(x => x.id === this.user.id);
		return (user?.role === 'admin');
	}

	/* TODO: @remove remove-subscription-required : subscription functions marked for deprecation
		thought maybe we will need them again in the future?
	*/
	cancelSubscription(): void {
		this.isProcessing = true;
		this.cancelError = null;
		this._orgService.cancelSubscription(this.selectedTeam).then(res => {
			this.isProcessing = false;
			this.stepper.next()
		}).catch((error)=>{
			this.cancelError = (error === 404 ) ? 'Could not cancel subscription at this time.' : null;
			this.isProcessing = false;
		})
	}

	onChangeSubscriptionSelect(sub): void{
		if(sub !== this.selectedTeam.subscription.plan_id){
			this.newPlanForDisplay = this.displayPlans.find(x => x.id === sub);
			this.togglePage('change-plan', false);
		}
	}

	changeSubscription(plan): void{
		let data = {plan_id: plan.id};
		this.isProcessing = true;
		this._orgService.changeSubscription(this.selectedTeam, data).then((res) => {
			this.selectedTeam.subscription = Subscription.setSubscription(JSON.parse(res));
			this.subscriptionPlanId = String(this.selectedTeam.subscription.plan_id);
			this.chargeBeePlansForDisplay = this.displayPlans.find(p => p.id === this.selectedTeam.subscription.plan_id) ?? pickPlanDisplayInfo();
			this.chargeBeePlansForDisplay.expirationMessage = createRenewalMessage(this.selectedTeam.subscription);
			this.isProcessing = false;
			this._alertService.notify(
				`Your plan has successfully been changed to ${ plan.name }.`,
				"success"
			);
			this.togglePage('view', false);
		}).catch((error)=>{
			this.isProcessing = false;
			this.cancelError = 'Could not change subscription at this time.';
		})
	}

	cancelChangeSubscription(): void{
		this.subscriptionPlanId = this.selectedTeam.subscription.plan_id;
		this.togglePage('view');
	}

	renewSubscription(sub): void {
		const confirmationMessage = `Your subscription will continue immediately.` +
			` Your next billing is on ${(Subscription.getEndOfSubscriptionDate(sub) as moment.Moment).format("MMM DD, YYYY")}`;
		this._dialog
			.open(ConfirmationModal, {
				data: confirmationMessage,
			})
			.afterClosed()
			.subscribe((changeConfirmed) => {
				if (changeConfirmed) {
					this._orgService
						.renewSubscription(this.selectedTeam.id)
						.then((res) => {
							this.selectedTeam.subscription = Subscription.setSubscription(JSON.parse(res));
							this.subscriptionPlanId = String(this.selectedTeam.subscription.plan_id);
							this.chargeBeePlansForDisplay = this.displayPlans.find(p => p.id === this.selectedTeam.subscription.plan_id) ?? pickPlanDisplayInfo();
							this.chargeBeePlansForDisplay.expirationMessage = createRenewalMessage(this.selectedTeam.subscription);
							this._alertService.notify(
								"Your plan has been successfully updated.",
								"success"
							);
						})
						.catch(() => {
							this.showUpdateError = true;
						});
				}
			});
	}
	/* TODO: @remove-end */

	getChargebeeSubscriptions(): Promise<any> {
		return this._cbService.getSubscriptions()
	}

	selectPlan(plan: Plan) {
		this.isProcessing = true;
		this.openChargebeeCheckout(plan, this.selectedTeam);
	}

	setSelectedPlanById = (plans: Plan[], plan_id) =>
		this.setSelectedPlan( plans.find( byId( plan_id ) ) );

	setSelectedPeriodByOrg = (org: Organization) => {
		this.selectedPeriod = (this.periodOptions.find( period => period.value === org.subscription.billing_period_unit ));
	}

	isSelectedPeriod = (period: Period) => this.selectedPeriod === period;

	setSelectedPlan = (plan: Plan) => this.selectedPlan = plan;

	isSelectedPlan = (plan: Plan) => this.selectedPlan === plan;

	isInTrial = (org: Organization) => org.subscription?.plan_id === "free-trial";

	showBillingButton = (org: Organization) => !(!org.subscription || this.isInTrial(org));

	openChargebeePortal( org: Organization ): void {
		// TODO: @remove temp-new-subscription
		// @ts-ignore
		if (flagLayer.isEnabled( availableFeatureFlags.newSubscription ) && this.userIsAdmin && !window.Cypress) {
			this.chargebeeInstance.setPortalSession( () =>
				this._cbService.getPortal( org.id )
			);
			const cbPortal = this.chargebeeInstance.createChargebeePortal();
			cbPortal.open({
				close: () => {
					this.updateOrgPlan(org);
				}
			});
		// @ts-ignore
		} else if (window.Cypress) {
			this.updateOrgPlan(org);
		} else {
			this._alertService.error( new Alert( "You do not have permission to manage billing on this team, please contact your Owner or Admin." ) );
		}
	}

	makeChargebeeInstance(): void {

		// @ts-ignore
		this.chargebeeInstance = window.Chargebee?.getInstance();

		if (!this.chargebeeInstance) {
			// Cannot reach Chargebee for some reason
			console.error( "Chargebee instance cannot be reached." );
			this.showChargebeeError = true;
		}
	}

	clearChargebeeInstance(): void {
		if (this.chargebeeInstance) {
			this.chargebeeInstance.logout();
		}
	}

	openChargebeeCheckout( plan: Plan, org: Organization ): void {

		// TODO: @remove temp-new-subscription
		// @ts-ignore
		if (flagLayer.isEnabled( availableFeatureFlags.newSubscription ) && this.userIsAdmin && !window.Cypress) {
			this.chargebeeInstance.openCheckout( {
				hostedPage: ( () => this._cbService.checkoutSubscriptions( org.id, plan.id ) ),
				success: () => this.onCheckoutSuccess( org, plan ),
				loaded: () => console.log( 'Chargebee Checkout instance loaded' ),
				error: ( err ) => this.onCheckoutError( err ),
				close: () => {
					this.isProcessing = false;
					this.clearChargebeeInstance();
				}
			} );
			// We can bypass this overlay in Cypress by intercepting the route.
			// @ts-ignore
		} else if (window.Cypress) {
			this._cbService.checkoutSubscriptions( org.id, plan.id ).then( () => {
				this.onCheckoutSuccess( org, plan );
			} ).catch( err => {
				this.onCheckoutError( err );
			} );
		} else {
			this._alertService.error( new Alert( "You do not have permission to purchase plans on this team, please contact your Owner or Admin." ) );
		}
	}

	onCheckoutSuccess = ( org: Organization, plan: Plan ) => {
		this._alertService.success( new Alert( `${ org.name } has successfully subscribed to the ${ plan.name } plan.` ) );
		this.isProcessing = false
		this.updateOrgPlan(org, plan);
	};

	updateOrgPlan = (org, plan?) => {
		if (plan) {
			Object.assign( org, {
				subscription: {
					plan_id: plan.id,
					status: "active",
					billing_period_unit: plan.period_unit
				},
				subscription_credits: ( plan.meta_data?.credits ?? org.subscription_credits )
			} );
			this.updateOrgPlanData(org);
		} else {
			this.showPlanChangeNotification = true;
		}
		this.checkPlanLoop( org );
	}

	updateOrgPlanData = (org: Organization) => {
		this.setSelectedPlanById(this._planList, org.subscription.plan_id); // Sets selected plan, for UI
		this.setSelectedPeriodByOrg(org); // Sets selected period, for UI
		Object.assign( this.selectedTeam, org ); // Updates org with new subscription
		this.updateOrgList.emit( true ); // Updates org list, so returning to this org has new data
		this._orgService.setActiveOrg( org, true ); // Updates org for updating the credits in org dropdown
		this.showPlanChangeNotification = false; // Removes notification, as update is complete
	}

	onCheckoutError = ( err ) => {
		this.isProcessing = false;
		console.error( err );
		this._alertService.error( new Alert( "Failed to subscribe, please try again." ) );
	};

	showPlanSelect = ( plan: Plan, org: Organization ): boolean =>
		!( this.isProcessing || this.showChargebeeError || this.isOrgPlan( plan, org ) );

	getPlanSelectText( plan: Plan, org: Organization ) {
		const MATCH_KEY = true as any;
		const matcher = {
			[MATCH_KEY]: "Change Plan",
			[this.isOrgPlan( plan, org ) as any]: "Current Plan",
			[!this.hasOrgPlan( org ) as any]: "Select Plan",
		};
		return matcher[MATCH_KEY];
	}

	getPlanStatusText = ( org: Organization ) =>
		planText[org.subscription?.status];

	getPlanStatusClass = ( org: Organization ) =>
		"plan-status-" + org.subscription.status;

	checkUserPermission = async ( org: Organization = this.selectedOrg ) => {
		this._permissionService.compareToOrgPermissionsPromise(org, "admin", this.user.id).then(rtn => {
			this.userIsAdmin = rtn;
		})
	}

	orgHasUpdatedSubscription = (oldOrg: Organization, newOrg: Organization) =>
		!isNil(newOrg.subscription?.plan_id) &&
		newOrg.subscription.plan_id !== "free-trial" &&
		newOrg.subscription.plan_id !== oldOrg.subscription?.plan_id;

	checkPlanLoop = ( oldOrg: Organization ) => {
		this._orgService.getById( oldOrg.id ).then( newOrg => {
			if (this.orgHasUpdatedSubscription(oldOrg, newOrg)) {
				this.updateOrgPlanData(newOrg);
			} else {
				if (numRetriesRemain > 0) {
					// Recheck after 3 seconds, only check number of times max (set to 10, for 30 seconds of checking)
					setTimeout( () => {
						numRetriesRemain -= 1;
						this.checkPlanLoop( oldOrg );
					}, 3000 );
				}
			}
		} );
	};

	getLicenseSeats() {
		this.teamRole = {
			admin: 0,
			process: 0,
			reader: 0
		};

		this.selectedTeam.users.forEach((user) => {
			if(user.role.value) {
				this.teamRole[user.role.value] += 1;
			} else {
				this.teamRole[user.role] += 1;
			}
		});
	}

}

